import Link from 'next/link';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import CloudinaryImage from '../common/CloudinaryImage';

import Container from './Container';

export default function Partner({
  partner,
  isPartner = false,
  isPartnerFrontend = false,
  istopbotompaddremoveinside,
  isIconBgClr = false,
  setModalCall,
  isHomeTxtClr = false,
  paddTop = false,
  paddBottom = false,
  isLeft,
}) {
  return (
    <section
      className={`bg-[#FAFAFA] ${istopbotompaddremoveinside ? 'md:py-[75px] py-[50px]' : paddTop ? 'md:pt-[55px] pt-[35px]' : paddBottom ? 'md:pb-[55px] pb-[35px]' : 'md:py-[55px] py-[35px]'}`}
    >
      <Container>
        <div
          className={`${istopbotompaddremoveinside ? 'xl:mb-[34px] mb-[30px]' : 'xl:mb-[34px] mb-[30px]'} xl:mb-[34px] mb-[30px]`}
        >
          <div
            className={`${partner?.description && 'mb-3'} ${isLeft ? 'text-left' : 'mx-auto text-center'} relative font-[800] xl:text-[50px] md:text-[32px] text-[26px] title_hd leading-tight`}
          >
            {partner?.title}
          </div>
          <p
            className={`${isLeft ? 'text-left' : 'mx-auto text-center'} font-[500] text-[16px] text-[#000B1C] max-w-[1100px] ${partner?.description && 'mb-[30px]'}`}
          >
            {partner?.description}
          </p>
        </div>

        <div
          className={
            isPartner
              ? 'grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1'
              : 'grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 md:gap-10 gap-5'
          }
        >
          {partner?.reasonList?.map((items, key) => (
            <div
              className={`${
                isPartner
                  ? `grid grid-cols-12 p-[30px] lg:border-[1px] lg:border-[#B8B8B8] ${
                      isPartner
                        ? 'hover:bg-[linear-gradient(181.36deg,_#0050D5_14.18%,_#020150_111.88%)] group transation-custom'
                        : ''
                    } ${
                      key === 0
                        ? 'lg:border-l-0 lg:border-t-0 lg:border-r-0'
                        : key === 1
                          ? 'lg:border-t-0'
                          : key === 2
                            ? 'lg:border-r-0 lg:border-l-0 lg:border-t-0'
                            : key === 3
                              ? 'lg:border-l-0 lg:border-r-0 lg:border-b-0 lg:border-t-0'
                              : key === 4
                                ? 'lg:border-b-0 lg:border-t-0'
                                : key === 5
                                  ? 'lg:border-r-0 lg:border-l-0 lg:border-b-0 lg:border-t-0'
                                  : key === 6
                                    ? 'lg:border-l-0 lg:border-b-0'
                                    : key === 7
                                      ? 'lg:border-l-0 lg:border-b-0'
                                      : key === 8
                                        ? 'lg:border-l-0 lg:border-b-0 lg:border-r-0'
                                        : ''
                    }`
                  : isPartnerFrontend
                    ? 'text-center transation-custom hover:shadow-[0px_0px_10px_#ccc] hover:bg-[#ffffff] rounded-[10px] p-[10px] md:p-[30px]'
                    : 'grid grid-cols-12 transation-custom hover:shadow-[0px_0px_10px_#ccc] hover:bg-[#f3f2ff] p-[30px]'
              }`}
              key={items?.id}
            >
              <div
                className={`${
                  isPartner
                    ? 'w-[60px] h-[60px] rounded-[100%] flex items-center justify-center mb-2'
                    : `col-span-12 mt-1 mb-6`
                }`}
              >
                {items?.image?.data?.map(img => (
                  <div
                    key={img?.id}
                    className={
                      isIconBgClr &&
                      'lg:w-[100px] lg:h-[100px] md:w-[100px] md:h-[100px] h-[60px] w-[60px] rounded-[100%] mx-auto flex items-center bg-[linear-gradient(180deg,_#0062FF_0%,_#003B99_100%)]'
                    }
                  >
                    <CloudinaryImage
                      backendImgUrl={img?.attributes?.url}
                      className={`${
                        isPartner
                          ? `w-[60px] mx-auto `
                          : isPartnerFrontend
                            ? 'lg:w-[60px] md:w-[60] w-[34px] mx-auto'
                            : `w-[50px]`
                      }`}
                      alt={img?.attributes?.alternativeText}
                      type="icon"
                    />
                  </div>
                ))}
              </div>
              <div className={`col-span-12 w-[100%]`}>
                {items?.title && (
                  <p
                    className={`${isHomeTxtClr ? 'text-[#303030]' : 'text-[#00124D] group-hover:text-[#fff]'} lg:text-[24px] text-[18px] font-[700] mb-2`}
                  >
                    {items.title}
                  </p>
                )}
                {items?.description && (
                  <p
                    className={`${isHomeTxtClr ? 'text-[#303030]' : 'text-[#303030]'} lg:text-[16px] md:text-[16px] text-[14px] font-[400] pb-2`}
                  >
                    {items?.description}
                  </p>
                )}
                {items?.editor && (
                  <ReactMarkdown
                    rehypePlugins={[rehypeRaw]}
                    className="text-[#303030] group-hover:text-[#fff] lg:text-[16px] md:text-[16px] text-[14px] font-[400] pb-2"
                    source={items?.editor}
                    transformImageUri={uri =>
                      uri.startsWith('http')
                        ? uri
                        : `${process.env.NEXT_PUBLIC_API_URL}${uri}`
                    }
                  >
                    {items?.editor}
                  </ReactMarkdown>
                )}
              </div>
            </div>
          ))}
        </div>

        <div className="text-center">
          {partner?.btn_link ? (
            <Link
              href={partner?.btn_link}
              className="mt-[35px] bg-[#FFA030] text-[#000D3A] border border-[#fff] group h-min relative rounded-[4px] overflow-hidden inline-block px-14 py-2 shadow-[10px_20px_40px_0px_#00000033]"
            >
              <span className="bg-[#fff] absolute top-0 left-0 flex w-full h-0 mb-0 transition-all duration-200 ease-out transform translate-y-0 group-hover:h-full opacity-1"></span>
              <span className="lg:text-[24px] text-[16px] group-hover:text-black flex items-center font-[600] relative">
                {partner?.btn_text}
              </span>
            </Link>
          ) : partner?.btn_text ? (
            <button
              onClick={() => setModalCall(partner?.btn_text)}
              className="mt-[35px] bg-[#FFA030] text-[#000D3A] border border-[#fff] group h-min relative rounded-[4px] overflow-hidden inline-block px-14 py-2 shadow-[10px_20px_40px_0px_#00000033]"
            >
              <span className="bg-[#fff] absolute top-0 left-0 flex w-full h-0 mb-0 transition-all duration-200 ease-out transform translate-y-0 group-hover:h-full opacity-1"></span>
              <span className="lg:text-[24px] text-[14px] group-hover:text-black flex items-center font-[600] relative">
                {partner?.btn_text}
              </span>
            </button>
          ) : (
            ''
          )}
        </div>
      </Container>
    </section>
  );
}
